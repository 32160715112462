<template>
   <div id='plans'>
      <img v-if="localeText == 'ar'"  class="imgoffer" src="@/assets/arabic-special-offer.png" alt="">
      <img  v-if="localeText == 'en'"  class="imgoffer" src="@/assets/english-special-offer.png" alt="">
      <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 180">
  <path fill="#c5c5c547" fill-opacity="1" d="M0,128L80,117.3C160,107,320,85,480,96C640,107,800,149,960,160C1120,171,1280,149,1360,138.7L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
</svg> -->
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220"><path fill="#c5c5c51f" fill-opacity="1" d="M0,224L60,208C120,192,240,160,360,144C480,128,600,128,720,144C840,160,960,192,1080,186.7C1200,181,1320,139,1380,117.3L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>

      <div class="startCareerPlansImage">
         <v-container>
            <div class="ab">
                   <h1  class="headerSection" v-if="localeText == 'ar'" >باقاتنا </h1>
               <h1  class="headerSection" v-if="localeText == 'en'" >CONNECT ME PACKAGES</h1>
              <p  v-if="localeText == 'ar'">احفظ وقتك ودعنا نوصلك للشركات اليوم</p>
              <p v-if="localeText == 'en'">SAVE TIME NOW BY LETTING US CONNECT YOU TO EMPLOYERS TODAY!</p>

            </div>
           
            <v-row>
            
               <div class="plan-cards__wrapper">
                  <app-plan-card
                     class="plan-card__mobile"
                     :planInfo="firstPlan"
                     :oldPrice="{ usd: '21.99', aed: '79.99 ' }"
                     @paymentInfo="sendPlan"
                  ></app-plan-card>
                  <!-- class="plan__gold-card" -->
                  <app-plan-card
                     class="plan-card__mobile"
                     :planInfo="secondPlan"
                     :oldPrice="{ usd: '24.99', aed: '89.99 ' }"
                     @paymentInfo="sendPlan"
                  ></app-plan-card>
                  <app-plan-card
                     :planInfo="thirdPlan"
                     :oldPrice="{ usd: '26.99', aed: '99.99 ' }"
                     @paymentInfo="sendPlan"
                  ></app-plan-card>
               </div>

               <v-col cols="12" md="6">
                  <div class="plans__buttonWrapper"></div>
               </v-col>
            </v-row>
         </v-container>
      </div>
   </div>
</template>

<script>
import axios from 'axios';
import planCard from '../components/homePlanCard.vue';
import { i18n } from '@/plugins/i18n';
export default {
   components: {
      appPlanCard: planCard,
   },
   data() {
      return {
         plans: [],

         firstPlan: {},
         secondPlan: {},
         thirdPlan: {},
      };
   },
   methods: {
      goToStepOne() {
         this.$store.state.stageCounter = 1;
      },
      sendPlan(checkOut) {
         let modal_title = '';
         if (this.$vuetify.rtl == true) {
            modal_title = 'يتم رفع معلوماتك الرجاء الإنتظار';
         } else {
            modal_title = 'Uploading your information please be patient';
         }
         this.$swal.fire({
            position: 'top',
            title: modal_title,
         });
         this.$swal.showLoading();
         this.$store.dispatch('secondStageDone', checkOut);
      },
   },
   computed: {
      localeText() {
         return i18n.locale;
      },
      plansBtnDisabled() {
         if (this.checkOut.selectedPlan == null) return true;
      },
      watchLang() {
         if (this.$vuetify.rtl == true) {
            return 'تسجيل';
         } else {
            return 'Register';
         }
      },
   },
   async created() {
      try {
         const plans = await axios.get('/plan');
         this.plans = plans.data.plans;
         this.firstPlan = this.plans[0];
         this.secondPlan = this.plans[1];
         this.thirdPlan = this.plans[2];
         if (!this.$store.getters.getLoadingModalStatus) {
            this.$swal.close();
         }
      } catch (err) {}
   },
};
</script>

<style>
.imgoffer{
    width: 13rem;
    position: absolute;
    opacity: 0.6;

}
.home-page .startCareerPlansImage {
   background: #fff;
   width: 100%;
   height: 100%;
   min-height: 100%;
         padding-top: 0;
    padding-bottom: 40px;

   box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.75);
}
.home-page .startCareerPlansImage h1{
         color: #f39f3c;
  
   margin: 0;

  
   
}
#plans{
   position: relative;
}
.ab{
    position: absolute;
    width: 100%;
  top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  
}
 .ab p{
       color: #589ca7;
    font-weight: 600;
font-size: 18px;
    text-align: center;
    line-height: 27px;
    }
</style>
