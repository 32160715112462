<template>
  <div>
    <div class="aboutUs--section1">
      <h1 class="headerSection">{{ watchLang.aboutUsHead }}</h1>
      <v-btn
        rounded
        x-large
        color="white"
        class="aboutUs__button animate__animated animate__swing"
        :class="{ homeButtonRTL: $vuetify.rtl }"
        @click="$router.push('/StartYourCarrer')"
        >{{ watchLang.abutUsButtonText }}</v-btn
      >
    </div>
    <div class="aboutUs--section2">
      <v-container>
        <v-row>
          <v-col sm="6" cols="12" class="aboutUs_section2TextCol">
            <h1 :class="{ aboutUsArText: $vuetify.rtl }">
              {{ watchLang.aboutUs }}
            </h1>
            <p
              :class="{
                aboutUsArParagraph: $vuetify.rtl,
              }"
            >
              {{ watchLang.aboutUsText }}
            </p>
          </v-col>
          <v-col sm="6" cols="12" class="aboutUs_section2ImageCol">
            <div class="section2-imageWrapper">
              <img
                src="@/assets/Connectme.png"
                alt="..."
                class="section2--image"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="aboutUs--section3">
      <h1  class="headerSection">{{ watchLang.ourWorkFlow }}</h1>
      <v-container>
        <div class="missions">
          <div
        
            v-for="(item, index) in ourWork"
            :key="index"
            class="section3--col"
          >
            <div class="section3--paragraph">
              <i
                class="section3--checkIcon"
               
              >
              <img    src="@/assets/Connectme.png" alt="">
              </i>
               <p> {{ item.description }}</p>
       
            </div>
          </div>
        </div>
      </v-container>
    </div>
           <app-values></app-values> 
           <app-partners></app-partners> 
  <app-whatsapp-button/>
    <app-footer></app-footer>
  </div>
</template>

<script>
import footer from "../components/footer";
import values from "../components/values";
import partners from "../components/partners.vue";
import whatsappButton from '../components/whatsappButton.vue'
export default {
  components: {
    appFooter: footer,
        appValues:values,
        appPartners:partners,
            appWhatsappButton:whatsappButton

  },
  data() {
    return {};
  },
  computed: {
    ourWork() {
      let ourWork = [
        {
          description: `Creating a connection between job seekers and employers using our dedicated team and technology.`,
        },
        {
          description: `Connecting Job seekers at an affordable cost and in record time.`,
        },
        {
          description:
            "Facilitating the time consuming process of searching for a job by making it efficient & effective.",
        },
      ];

      if (this.$vuetify.rtl == true) {
        ourWork = [
          {
            description: `خلق رابطة احترافية بين الباحثين عن عمل والمؤسسات الحديثة باعتماد أحدث الطرق والتقنيات، وتأمين أعمال أفضل لحياة
أفضل.`,
          },
          {
            description: `تسهيل عملية البحث عن عمل ضمن أفضل البيئات ولجميع الأفراد.`,
          },
          {
            description: `إيصال أصحاب المهارات إلى الأماكن التي تبحث عنهم، بأسرع وقت وأقل تكلفة.`,
          },
        ];
      }
      return ourWork;
    },
    watchLang() {
      let texts = {
        aboutUsSmallText: "Any Location, Any Time",
        abutUsButtonText: "CONNECT NOW!",
        aboutUsHead: "Connect with Connect me",
        aboutUs: "About us",
        ourWorkFlow: "Our mission",
        aboutUsText: `Connect Me specializes in connecting individuals seeking for jobs
In the UAE from around the world. Our dedicated team distributes CVs &
Connects our users with employers. Connect me values time and is specialized
in providing the service in an efficient and affordable manner to cater to
Individuals who are of all professions and walks of life`,
      };
      if (this.$vuetify.rtl == true) {
        texts.aboutUsSmallText = "في أي مكان و في أي وقت";
        texts.abutUsButtonText = "ابدأ رحلتك";
        texts.aboutUsHead = "اتصل بالعالم";
        texts.aboutUs = "من نحن";
        texts.ourWorkFlow = "مهمتنا";
        texts.aboutUsText =
          "شركة مختصة بربط الشركات بالأشخاص الباحثين عن عمل بأسرع الطرق وأقل التكاليف، نقوم برفد الشركات بأفضل المواهب وتأمين فرص عمل لائقة ومناسبة لجميع المهن والوظائف والمناصب.";
      }
      return texts;
    },
  },
  created() {
    this.$store.state.stageCounter = 1;
  },
};
</script>

<style>
.headerSection{
  font-family: "TRENCH", Tajawal, sans-serif;
    font-weight: bold;
   font-size: 68px;
   text-align: center;
}
.aboutUs--section1 {
  width: 100%;
  text-align: center;
  padding: 50px 0;
  background: #f39f3c;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutUs--section1 h1{
font-family: "TRENCH" ,Tajawal, sans-serif;
    font-weight: bold;
    /* font-size: 62px; */

}
.aboutUs--section2 h1 {
    font-family: "TRENCH" ,Tajawal, sans-serif;
    font-weight: bold;
    font-size: 53px;
    color: #f39f3c;

}
.aboutUs--section3 h1{

  color: #fff;
}
.aboutUs--section3 .missions{
      width: fit-content;
    margin: auto;
}
.aboutUs--section2 p{
  margin-bottom: 0;
    font-weight: 600;

}
.aboutUs__button {
  color: #f39f3c !important;
  text-transform: none;
  transition: 0.5s;
}
.aboutUs__button:hover {
  background: #599ca7 !important;
  transition: 0.5s;
  color: white !important;
}
.aboutUs--section2 {
  margin: 100px 0;
  color: #599ca7;
}
.aboutUs_section2TextCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.aboutUs_section2ImageCol {
  display: flex;
  justify-content: center;
}
.section2-imageWrapper {
  height: auto;
  width: 250px;
}
.section2--image {
  width: 100%;
}
.aboutUs--section3 {
 margin-top: 100px;
    padding: 50px 0;
    width: 100%;
    background: #f7af59;
    text-align: center;
}
.section3--col:hover .section3--checkIcon {
  color: green;
  transition: 0.5s;
  transform: scale(1.2);
}
.section3--singleContent {
  display: flex;
  align-items: center;
}
.section3--checkIcon {
  font-size: 1.1rem;
  padding-right: 10px;
  transition: 0.5s;
}
.section3--checkIcon   img{

    width: 25px;
  
}
.section3--paragraph {


    text-align: start;
    display: flex;
    font-size: 18px;
    margin: 14px 0;
}
.section3--paragraph p{
  margin-bottom: 0;

}
.aboutUsArText,
.aboutUsArParagraph {
  text-align: start;
}
.aboutUsArParagraph {
  font-size: 1.1rem;
}
.section3--checkIconAr {
  padding-right: 0;
  padding-left: 10px;
}
@media only screen and (max-width: 457px) {
  .section3--checkIcon {
    font-size: 1.2rem;
  }
}
</style>