<template>
  <div class="homePage__section3Wrapper">
      <h1  class="headerSection">{{ watchLang.ourValue }}</h1>
      <v-container>
        <v-row>
          <v-col
            md="4"
            cols="12"
            v-for="(data, index) in circleData"
            :key="index"
          >
            <div class="section3__OptionWrapper">
              <div class="section3__circle">
                <i :class="data.icon" aria-hidden="true"></i>
              </div>
              <h3>{{ data.title }}</h3>
              <p class="ourValue-cardParagraph">
                {{ data.description }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>
<script>
export default {
  computed :{
 circleData() {
      if (this.$vuetify.rtl) {
        let circleDataArr = [
          {
            icon: "fa fa-star",
            title: "الزمن",
            description: `كل خطوة في عملنا مصممة ليتم تنفيذها بزمن قياسي وبأقل مجهود ممكن.`,
          },

          {
            icon: "fa fa-handshake-o",
            title: "الشفافية",
            description: `نعتمد في كافة أعمالنا على شفافية مطلقة مع كافة الجهات المعنية والمستفيدة.`,
          },
          {
            icon: "fa fa-globe",
            title: "التواصل",
            description: `بشبكة واسعة من الشركات على امتداد الجغرافية الإماراتية سنعمل على نموها بشكل مستمر.`,
          },
        ];
        return circleDataArr;
      } else {
        let circleDataArr = [
          {
            icon: "fa fa-star",
            title: "Time",
            description: `We value your time, that is why our dedicated team
connects your CV with employers in record time.`,
          },

          {
            icon: "fa fa-handshake-o",
            title: "Transparency",
            description: `We guarantee absolute transparency with all stakeholders and beneficiaries.`,
          },
          {
            icon: "fa fa-globe",
            title: "Connecting",
            description: `We are continuously expanding our database with a wide network of employers within the UAE.`,
          },
        ];
        return circleDataArr;
      }
    },
    
    
  watchLang() {
      let texts = {
     
        ourValue: "VALUES",
      };
      if (this.$vuetify.rtl) {
      
        texts.ourValue = "قيمنا";
      }
      return texts;
    },
  },
   created() {
    this.$store.state.stageCounter = 1;
  },
 
};
</script>
