<template>
  <v-app>
    <v-main>
      <offline @detected-condition="handleConnectivityChange"></offline>
      <app-nav-bar></app-nav-bar>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import navBar from "./components/navBar";
import offline from "v-offline";
import axios from "axios";
export default {
  components: {
    appNavBar: navBar,
    offline,
  },
  methods: {
    handleConnectivityChange(status) {
      let modal_title = "";
      if (status == false) {
        if (this.$vuetify.rtl) {
          modal_title = "الرجاء التأكد من اتصالك بالانترنت";
        } else {
          modal_title = "Please check your internet connection";
        }
        this.$swal.fire({
          position: "top",
          icon: "error",
          title: modal_title,
        });
      }
      // console.log("wah", status);
    },
  },
  async created() {
    try {
      const countries = await axios.get("/country", {
        params: {
          size: 1000,
        },
      });
      const categories = await axios.get("/category", {
        params: {
          size: 1000,
        },
      });
      this.$store.dispatch("setCountries", countries.data.countries);
      this.$store.dispatch("setCategories", categories.data.categories);
      // const locationResponse = await axios.get("http://ip-api.com/json", {
      //   headers: {
      //     Origin: "http://localhost:8080",
      //   },
      // });

      // console.log("location hehe", locationResponse);
    } catch (err) {}
  },
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
#app {
  overflow: hidden;
}
.swal2-loader {
  border-color: #f39f3c transparent #f39f3c transparent !important;
}
.theme--dark.v-application {
  background: white !important;
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  padding-right: 0 !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f0b268;
  border-radius: 20px;
  transition: all 1s ease;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6ea6af;
  transition: all 1s ease;
}
</style>